/** @jsxImportSource theme-ui */

type Props = {
  children: React.ReactNode;
  className?: string;
  id?: string;
};

const H2 = ({ children, ...props }: Props) => {
  return (
    <h2
      {...props}
      sx={{
        display: "inline-block",
        fontSize: ["lg", null, "xl", null, "xxl"],
        fontWeight: "extraBold",
        lineHeight: "xs",
      }}
    >
      {children}
    </h2>
  );
};

export default H2;
