import Page from "../components/Page";
import SectionAbout from "../components/SectionAbout";
import SEO from "../components/SEO";

const AboutPage = (props: any) => {
  const { transitionStatus, uri } = props;

  return (
    <Page transitionStatus={transitionStatus} uri={uri}>
      <SEO title="About" />
      <SectionAbout />
    </Page>
  );
};

export default AboutPage;
